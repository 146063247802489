<template>
	<v-container>
		<v-card>
			<v-card-title align="left" class="headline primary cash-receipt-title">
				<h5>Cash Receipt Entries</h5>
			</v-card-title>
			<v-tabs background-color="primary accent-4" dark>	 
				<v-tab @click="trxnStatus = 1; trxnStatusName = 'Drafting'">Drafting</v-tab>
				<v-tab @click="trxnStatus = 3; trxnStatusName = 'Approval 1'">Approval 1</v-tab>
				<v-tab @click="trxnStatus = 4; trxnStatusName = 'Approval 2'">Approval 2</v-tab>
				<v-tab @click="trxnStatus = 5; trxnStatusName = 'Approval 3'">Approval 3</v-tab>
				<v-tab @click="trxnStatus = 7; trxnStatusName = 'Approved'">Approved</v-tab>
				<!-- <v-tab @click="trxnStatus = 8; trxnStatusName = 'Confidential'">Confidential</v-tab> -->
			</v-tabs>
			<v-data-table
				:headers="headersCashReceipt"
				:items="filteredItems"
				sort-by="document_number"
				class="elevation-1"
				:search="search"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>{{trxnStatusName}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-layout wrap>
						<v-flex md3 />
						<v-spacer/>
						<v-flex xs12 sm6 md3>
							<v-text-field append-icon="mdi-calendar" label="Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
							<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
								<v-date-picker
									v-model="month_year"
									type="month"
									@change="month_year_picker = false; getData()"
								></v-date-picker>
							</v-dialog>
						</v-flex>
						<v-spacer/>
						<v-flex xs12 sm6 md5>
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-flex>
						</v-layout>
					</v-toolbar>
				</template>
				<template v-slot:[`item.total_amount`]="{ item }">
					<span>{{formatAmount(item.total_amount)}}</span>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<!-- Approve/Reject - Checks if the user is an approver -->
					<template v-if="trxnStatus !== 7 && trxnStatus !== 6 && trxnStatus !== 1">
						<template>
							<v-icon color="primary" class="mr-2" small @click="viewItem(item)"
									>mdi-clipboard-check-multiple-outline</v-icon
								>
						</template>
					</template>


					<!-- Check if the current user is also the preparer of the item -->
					<template v-if="item.preparer === currentUserFullName && trxnStatus === 1 && role_access > 2">
						<v-icon color="primary" class="mr-2" small @click="editItem(item)"
							>edit</v-icon
						>
					</template>
					<template v-else-if="item.preparer !== currentUserFullName && trxnStatus === 1 || role_access < 2">
						<v-icon color="red" class="mr-2" small
							>mdi-pen-off</v-icon>
					</template>
					<template>
						<v-icon color="primary" v-if="trxnStatus < 7 && item.is_or_ar !== 0" class="mr-2" small @click="viewDetail(item)"
							>mdi-printer-eye</v-icon
						>
					</template>
					<!-- Edit - Checks if the user is an approver -->
					<template v-if="trxnStatus !== 7 && trxnStatus !== 6">
						<!-- Checks if the current user is an approver -->
						<template v-if="currUser.max_amount !== null">

							<!--  By Approver Access -->
							<!-- Approver 1 Access - Approver 2 and 3 are Restricted -->
							<template v-if="parseFloat(currUser.max_amount) > 0 && parseFloat(currUser.max_amount) < 100000.01">
								<!-- <v-icon color="primary" v-if="trxnStatus !== 4 && trxnStatus !== 5" class="mr-2" small @click="editItem(item)"
									>edit</v-icon
								> -->
								<!-- <v-icon color="red" v-if="trxnStatus === 4 || trxnStatus === 5" class="mr-2" small
									>mdi-pen-off</v-icon
								> -->
							</template>

							<!-- Approver 2 Access - Approver 3 is Restricted -->
							<template v-else-if="parseFloat(currUser.max_amount) > 100000 && parseFloat(currUser.max_amount) < 1000000.01">
								<!-- <v-icon color="primary" v-if="trxnStatus !== 5" class="mr-2" small @click="editItem(item)"
									>edit</v-icon
								> -->
								<!-- <v-icon color="red" v-if="trxnStatus === 5" class="mr-2" small
									>mdi-pen-off</v-icon
								> -->
							</template>

							<!-- Approver 3 Access - All Access -->
							<template v-else-if="parseFloat(currUser.max_amount) > 1000000">
								<!-- <v-icon color="primary" class="mr-2" small @click="editItem(item)"
									>edit</v-icon
								> -->
							</template>
						</template>


						<!-- Otherwise, Check if the current user is also the preparer of the item -->
						<!-- <template v-else-if="currUser.max_amount === null && item.preparer === currentUserFullName">
							<v-icon color="primary" class="mr-2" small @click="editItem(item)"
								>edit</v-icon
							>
						</template> -->

						<!-- <template v-else> -->
							<!-- <v-icon color="red" class="mr-2" small
								>mdi-pen-off</v-icon
							> -->
						<!-- </template> -->
					</template>

					<template v-if="trxnStatus === 6 && item.preparer === currentUserFullName">
						<v-icon color="primary" class="mr-2" small @click="viewItem(item)"
							>mdi-clipboard-check-multiple-outline</v-icon
						>
						<v-icon color="primary" v-if="item.document_type == 'CW'" class="mr-2" small @click="downloadTextfileItem(item)"
							>mdi-file-download-outline</v-icon
						>
						<v-icon color="red" v-if="item.document_type !== 'CW'" class="mr-2" small
							>mdi-download-off-outline</v-icon
						>
					</template>

					<template v-if="trxnStatus === 6 && item.preparer !== currentUserFullName">
						<v-icon color="red" class="mr-2" small
							>mdi-alert-octagon-outline</v-icon
						>
						<v-icon color="red" class="mr-2" small
							>mdi-download-off-outline</v-icon
						>
					</template>
					<v-icon color="primary" v-if="trxnStatus === 7 && role_access === 5 && item.reverse_flag === 0" class="mr-2" small @click="cancelCashReceipt(item)"
						>mdi-close-circle-outline</v-icon
					>
					
					<v-icon color="red" v-if="trxnStatus === 7 && item.reverse_flag === 1" class="mr-2" small>
						mdi-close-circle-outline</v-icon
					>
					<v-icon color="primary" v-if="trxnStatus === 7" class="mr-2" small @click="viewItem(item)"
						>mdi-eye</v-icon
					>
					<!-- <v-icon color="primary" v-if="trxnStatus === 7" class="mr-2" small @click="downloadItem(item)"
						>mdi-folder-download-outline</v-icon
					> -->
					<v-icon color="primary" v-if="trxnStatus === 7" class="mr-2" small @click="generateBIRForm(item)"
						>mdi-book-information-variant</v-icon
					>
					<v-icon color="primary" v-if="trxnStatus === 7 && item.is_or_ar !== 0" class="mr-2" small @click="printItem(item)"
						>mdi-printer-eye</v-icon
					>
					<!-- <v-icon small color="red" @click="deleteItem(item)">delete</v-icon> -->
				</template>
			</v-data-table>
			<v-btn
				bottom
				fixed
				right
				fab
				dark
				@click="setBatchDocument"
				class="mx-2"
				color="primary"
				v-if="role_access > 1"
			>
				<v-icon dark>mdi-plus</v-icon>
			</v-btn>
			<div class="text-center">
				<v-dialog
					v-model="viewDialog"
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
				>
					<v-trx-detail ref="detailTrxdisb" :batchDetails.sync="batchDetails" :documentDetails.sync="documentDetails" :viewDialog.sync="viewDialog"></v-trx-detail>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="editDialog"
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
				>
					<v-trx-edit ref="editTrxdisb" :batchDetails.sync="batchDetails" :documentDetails.sync="documentDetails" :editDialog.sync="editDialog"></v-trx-edit>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="attachmentDialog"
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
				>
					<v-trx-downloads ref="attachmentTrxdisb" :batchDetails.sync="batchDetails" :attachmentDialog.sync="attachmentDialog"></v-trx-downloads>
				</v-dialog>
			</div>
		</v-card>
		<div class="text-center">
			<v-dialog
				v-model="unsavedTransaction"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Unsaved Transaction
					</v-card-title>
					<br>
					<v-card-text>
						You have unsaved transactions, Do you want to retrieve it?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							to="/trxcr/create"
						>
							Yes
						</v-btn>
						<v-btn
							color="red"
							text
							@click="removeTransaction()"
						>
							No
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<div class="text-center">
				<v-dialog
					v-model="createDisbDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Cash Receipt
						</v-card-title>
						<br>
						<v-card-text>
							Sorry, the accounting period for this month is already <b>CLOSED</b>.
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="createDisbDialog = false"
							>
								OK
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="voidDisbDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Void Cash Receipt
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to void this transaction?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="voidInfoDialog = true;voidDisbDialog = false"
							>
								Yes
							</v-btn>
							<v-btn
								color="error"
								text
								@click="voidDisbDialog = false"
							>
								No
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="voidInfoDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Void Cash Receipt
						</v-card-title>
						<br>
						<v-card-text>
							Batch Number: <b>{{selectedItem.batch_number}}</b>
							<br>
							<br>
							Please state the reason:
							<v-text-field v-model="voidRemarks" dense required></v-text-field>
							File Upload:
							<div class="large-12 medium-12 small-12 cell">
								<label>
									<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
								</label>
							</div>
						</v-card-text>
						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="cancelTransaction()"
							>
								Confirm
							</v-btn>
							<v-btn
								color="error"
								text
								@click="voidInfoDialog = false"
							>
								Cancel
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</div>
	</v-container>
</template>

<script>
import numeral from 'numeral';
import detailCashReceipt from './detailCashReceipt.vue';
import editCashReceipt from '@/components/pages/trx/cashreceipt/edit/editCashReceipt.vue';
import attachmentCashReceipt from './attachmentCashReceipt.vue';
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import io from 'socket.io-client'; 
import jsonToFormData from 'json-form-data';
//import AcknowledgementReceiptVue from '../../../../views/maint/AcknowledgementReceipt.vue';

export default {
	components: {
		'v-trx-detail': detailCashReceipt,
		'v-trx-edit': editCashReceipt,
		'v-trx-downloads': attachmentCashReceipt,
	},
	data() {
		return {
			module_name: 'Cash Receipts',
			role_access: 1,
			trxnStatus:1,
			trxnStatusName: 'Drafting',
			search: '',
			month_year: '',
			month_year_picker: false,
			currentUserFullName: '',
			unsavedTransaction: false,
			createDisbDialog: false,
			voidInfoDialog: false,
			isApprover: false,
			batchDetails: [],
			documentDetails:[],
			fileDetails: [],
			selectedItem: {},
			data: [],
			dataCoas: [],
			nextPageCoas: '',
			nextPage: '',
			nextPageTrx: '',
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			months: [
				{name: 'January', value: 1},
				{name: 'February', value: 2},
				{name: 'March', value: 3},
				{name: 'April', value: 4},
				{name: 'May', value: 5},
				{name: 'June', value: 6},
				{name: 'July', value: 7},
				{name: 'August', value: 8},
				{name: 'September', value: 9},
				{name: 'October', value: 10},
				{name: 'November', value: 11},
				{name: 'December', value: 12},
			],
			selectedYear: null,
			selectedMonth: null,
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			],
			voidDisbDialog: false,
			voidRemarks: '',
			upload_files: '',
		};
	},
	computed: {
		...mapGetters({
			trxdisbs: 'trxdisb/trxdisbs',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			headers: 'trxdisb/headers',
			headersCashReceipt: 'trxdisb/headersCashReceipt',
			editedItem: 'trxdisb/editedItem',
			defaultItem: 'trxdisb/defaultItem',
			dialog: 'trxdisb/dialog',
			editedIndex: 'trxdisb/editedIndex',
			editDialog: 'trxdisb/editDialog',
			viewDialog: 'trxdisb/viewDialog',
			attachmentDialog: 'trxdisb/attachmentDialog',
			valid: 'trxdisb/valid',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			dist_data: 'trxdisb/dist_data',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData'
		}),
		years() {
			const year = new Date().getFullYear();
			const generateYears = Array.from({ length: year - 1900 },(value, index) => 1901 + index);
			generateYears.sort();
			return generateYears.reverse();
		},
		filteredItems() {
			let app = this;
			let txn = this.trxdisbs;
			let txnFiltered = [];
			let listDrafting = [];
			let listNonRegApp1 = [];
			let listNonRegApp2 = [];
			let listNonRegApp3 = [];
			let listReleasing = [];
			let listReleased = [];
			let listConfidential = [];
			let listCancelled = [];
			txn = Object.values(txn);
			let filteredTxn = txn.filter(transaction => {
				if (transaction.preparer !== this.currentUserFullName) {					
					return transaction.is_confidential !== 1;
				} else {
					return transaction;
				}
			});

			if (this.isApprover === false) {
				txn = filteredTxn;
			}
			
			if (txn !== undefined) {
				txn.forEach(element => {
					if (element !== null && element.document_type == 'CR') {
						if(element.processing < 1){

							switch(element.status)
							{
							// case 0: 
							// 	listDisbTxn.push(element);
							// 	break;
							case 1:
								listDrafting.push(element);
								break;
							// case 2:
							// 	listRegApproval.push(element);
							// 	break;	
							case 3:
								listNonRegApp1.push(element);
								break;
							case 4:
								listNonRegApp2.push(element);
								break;
							case 5:
								listNonRegApp3.push(element);
								break;	
							case 6:
								listReleasing.push(element);
								break;
							case 7:
								listReleased.push(element);
								break;	
							case 8:
								listConfidential.push(element);
								break;
							case 25:
								listCancelled.push(element);
							}
						}
					}
					

					switch(app.trxnStatus)
					{
					// case 0:
					// 	txnFiltered = listDisbTxn;
					// 	break;
					case 1:
						txnFiltered = listDrafting;
						break;
					// case 2:
					// 	txnFiltered = listRegApproval;
					// 	break;	
					case 3:
						txnFiltered = listNonRegApp1;
						break;
					case 4:
						txnFiltered = listNonRegApp2;
						break;
					case 5:
						txnFiltered = listNonRegApp3;
						break;	
					case 6:
						txnFiltered = listReleasing;
						break;
					case 7:
						txnFiltered = listReleased;
						break;	
					case 8:
						txnFiltered = listConfidential;
						break;
					case 25:
						txnFiltered = listCancelled;
						break;
					} 
				});	
			}
			
			txnFiltered.forEach(i => {
				i.date_encoded = format(parseISO(i.date_encoded), 'yyyy-MM-dd');
				if (i.document_date !== undefined) {
					var count = i.document_date.split('-').length;
					if(count > 3){
						i.date = 'Have multiple dates';
					} else {
						i.date = i.document_date.replace(/,/g,'');
					}
				}
			});
			return txnFiltered;
			// return txnFiltered.filter((i) => {				
			// 	if (i.document_date !== undefined) {
			// 		let getDocumentDate = format(parseISO(i.document_date), 'yyyy-MM-dd');


			// 		if (!this.selectedMonth && this.selectedYear) {
			// 			return getYear(getDocumentDate) === this.selectedYear.toString();
			// 		} else if (!this.selectedYear && this.selectedMonth) {
			// 			return getMonth(getDocumentDate) === this.selectedMonth.toString();
			// 		} else if (this.selectedYear && this.selectedMonth) {
			// 			return getYear(getDocumentDate) === this.selectedYear.toString() 
			// 			&& getMonth(getDocumentDate) === this.selectedMonth.toString();
			// 		} else {
			// 			return !this.selectedYear || !this.selectedMonth;
			// 		}
			// 	} else {
			// 		return !this.selectedYear || !this.selectedMonth;
			// 	}
			// });
		}
	},
	watch: {
		editedIndex: {
			handler() {
			},
			deep: true
		},
		trxdisbs: {
			handler() {
				//trxdisbsthis.getAllData();
			},
			deep: true
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		trxdisbDetails: {
			handler(val) {

				this.$data.batchDetails.date = format(new Date(), 'yyyy-MM-dd');
				this.$data.batchDetails.document_type = val[0].document_type;
				this.$data.batchDetails.currency_id = val[0].currency_id;
				this.$data.batchDetails.dollar_rate = val[0].dollar_rate;
				this.$data.batchDetails.document_date = val[0].document_date;
				this.$data.batchDetails.is_confidential = val[0].is_confidential;
				this.$data.batchDetails.bank_account_id = val[0].bank_account_id;
				this.$data.batchDetails.approved_remarks = val[0].approved_remarks;

				val.forEach(docData => {
					let filterDetails = [];

					if (docData.document_type === 'CW') {
						filterDetails = docData.cw_details.filter(detail => detail.document_number === docData.document_number && detail.batch_number === docData.batch_number);
					} else {
						filterDetails = docData.details.filter(detail => detail.document_number === docData.document_number && detail.batch_number === docData.batch_number);
					}


					//computes the total debit in a specific document
					const totalDebit = filterDetails.reduce((sum, details) => {
						if (details.debit_amount === '') {
							details.debit_amount = 0;
						}

						return sum + parseFloat(details.debit_amount);
					}, 0);

					//computes the total credit in a specific document
					const totalCredit = filterDetails.reduce((sum, details) => {
						if (details.credit_amount === '') {
							details.credit_amount = 0;
						}

						return sum + parseFloat(details.credit_amount);
					}, 0);

					docData.overallDebit = totalDebit;
					docData.overallCredit = totalCredit;

					docData.disbursement_type = docData.regular_trx_id > 0 ? 1 : 2;
					docData.regular_trx_type = (docData.disbursement_type == 1 && docData.regular_trx_id > 0) ? 1 : 2;
					docData.regular_trx_selected = docData.regular_trx_id !== process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER ? docData.regular_trx_id : 0;
				});

				this.$data.documentDetails = val;

			},
			deep: true
		},
		acctperiodmains:{
			handler(){
				this.getAcctperiodmain();
			}
		}
	},
	created() {
		this.socket = io(process.env.VUE_APP_SOCKET_URL); 
	},
	mounted() {
		const that = this;
		this.selectedItem = {};
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: that.month_year + '-01'});
		this.$store.dispatch('acctperiodmain/getAcctperiodmains');
		this.$store.dispatch('coa/getCoaDropdown');
		this.socket = io(process.env.VUE_APP_SOCKET_URL); 

		
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}

		this.$data.currentUserFullName = this.currUser.user_last_name + ', ' + this.currUser.user_first_name;
		if (this.currUser.max_amount !== null) {
			this.isApprover = true;
		} else {
			this.isApprover = false;
		}

		if (this.$route.params.batch_number) {
			let currentItem = this.trxdisbs.filter(item => item.batch_number === this.$route.params.batch_number);
			
			if (this.$route.params.action === 'view') {
				this.viewItem(currentItem[0]);
			} else if (this.$route.params.action === 'draft') {
				this.editItem(currentItem[0]);
			}
			
			setTimeout(function() {
				that.$route.params.batch_number = '';
			}, 2000);
		}
		
		if(this.dist_data){
			let distData = this.dist_data;
			if ('cash_receipt' in distData){
				if('headers' in this.dist_data.cash_receipt){
					if(distData.cash_receipt.headers.length != 0){
						this.unsavedTransaction = true;
					}
				}
			}
		}
	},
	methods: {
		async downloadItem(item) {
			this.$data.batchDetails = [];
			this.documentDetails = [];
			this.$data.batchDetails.batch_number = item.batch_number;
			this.$data.batchDetails.total_amount = item.total_amount;

			let disbList = this.trxdisbs;
			disbList = Object.values(this.trxdisbs);

			this.$store.dispatch('trxdisb/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxdisb/setAttachmentDialog', true);
			await this.$store.dispatch('trxdisb/getDetails', item.batch_number);
		},

		async downloadTextfileItem(item) {
			let url = process.env.VUE_APP_BASE_URL + '/trx/download-cw/' + item.batch_number;
			window.open(url, '_blank'); 
		},
		async viewItem(item) {
			this.batchDetails = [];
			this.documentDetails = [];
			this.batchDetails.batch_number = item.batch_number;
			this.batchDetails.total_amount = item.total_amount;
			this.batchDetails.approved_by = item.approved_by; 
			this.batchDetails.status = item.status;

			let disbList = this.trxdisbs;
			disbList = Object.values(this.trxdisbs);
			this.$store.dispatch('trxdisb/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxdisb/setPreparer', item.preparer);
			this.$store.dispatch('trxdisb/getTrxdisb', item.batch_number);
			await this.$store.dispatch('trxdisb/getDetails', item.batch_number);
			if(this.trxnStatus !== 7){
				this.updateSocket(item);
			}
		},
		async editItem(item) {
			this.batchDetails = [];
			this.documentDetails = [];
			this.batchDetails.batch_number = item.batch_number;
			this.batchDetails.total_amount = item.total_amount;
			this.batchDetails.isConfidential = '0';
			this.batchDetails.document_type = '1';
			this.batchDetails.status = item.status;

			let disbListEdit = this.trxdisbs;
			disbListEdit = Object.values(this.trxdisbs);
			this.$store.dispatch('trxdisb/setEditedIndex', disbListEdit.indexOf(item));
			this.$store.dispatch('trxdisb/editTrxdisb', item.batch_number);
			await this.$store.dispatch('trxdisb/getDetails', item.batch_number);
			this.updateSocket(item);
		},
		cancelCashReceipt(item) {
			let date = format(new Date(), 'yyyy-MM-dd');
			this.selectedItem = item;
			this.voidRemarks = '';
			this.upload_files = '';
			let isBookOpen = this.checkBookOpen(date);
			if (isBookOpen === true) {
				this.voidDisbDialog = true;
			} else {
				this.createDisbDialog = true;
			}
		},
		cancelTransaction(){

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let data = {};
			data.approved_by = this.currUser.id;
			data.document_type = this.selectedItem.document_type;
			data.batch_number = this.selectedItem.batch_number;
			data.remarks = this.voidRemarks;
			data.image_title = [];
			data.upload_file = [];
			for( var i = 0; i < this.upload_files.length; i++ ){
				let file = this.upload_files[i];
				data.image_title.push(file.name);
				data.upload_file.push(file);
			}
			let convertedFormData = jsonToFormData(data, options);
			this.$store.dispatch('trxdisb/setisLoading', true);
			this.$store.dispatch('trxdisb/voidTrxDisb', {data: convertedFormData, type: this.selectedItem.document_type});
					
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		deleteItem(item) {
			this.$swal.fire({
				title: 'Delete!',
				text:
				item.batch_number +
				'(' +
				item.document_number +
				')' +
				'? You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then(result => {
				if (result.value) {
					this.$store.dispatch('trxdisb/setisLoading', true);
					this.$store.dispatch('trxdisb/deleteTrxdisb', item);
				}
			});
		},
		generateBIRForm(item) {
			this.$router.push({
				name: 'bir-form',
				params: {
					data: item
				} 
			});
		},
		async printItem(item) {
			if (new Date(item.date) < new Date('2023-03-01')){
				if(item.is_or_ar === 1 || item.is_or_ar === 3){
					let routeData = this.$router.resolve({path: '/preview-OR/'+btoa(JSON.stringify(item)), data: item});
					window.open(routeData.href, '_blank');
				} 
				
				if(item.is_or_ar === 2 || item.is_or_ar === 3){
					let routerData2 =  this.$router.resolve({path: '/preview-AR/'+btoa(JSON.stringify(item)), data: item});
					window.open(routerData2.href, '_blank');
				} 

				if(item.is_or_ar === 0){
					let routeData = this.$router.resolve({path: '/preview-trx/'+btoa(JSON.stringify(item)), data: item});
					window.open(routeData.href, '_blank'); 
				}
			} else {
				const res = await this.$store.dispatch('trxdisb/getEORARData', item.batch_number);
				var newBlob = new Blob([res], { type: 'application/pdf' });
				var newFileURL = URL.createObjectURL(newBlob);
				window.open(newFileURL, '_blank');
			}
		},
		updateSocket(item) {
			/* 
			to use the lock mechanism
			set the processing propery of the editedItem to 1
			when the value is 1, the current user can modify the details, without interfering of others.
			EXAMPLE: app.trxdisbs[app.editedIndex].processing = 1;

			to display back the item to the data table
			set the processing property to 0;
			EXAMPLE: app.trxdisbs[app.editedIndex].processing = 0;

			to transfer the data to the other tab
			change the value property depends on the destination of the tab

			0 - For disbursement
			1 - Drafting
			2 - Reg Approval
			3 - Approval 1
			4 - Approval 2
			5 - Approval 3
			6 - For Release
			7 - Released
			8 - Confidential
			

			*/
			/* EXAMPLE */
			// this.trxdisbs[this.editedIndex].processing = 1;			
			// this.socket.emit('updatedTrxDisb',this.trxdisbs); 

			/* update the data in the API  */
			let data = {};
			data.batch_number = item.batch_number;
			data.value = {
				processing : 1,
				document_type : item.document_type,
				status : item.status,
				_method: 'PUT'
			};

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			let convertedFormData = jsonToFormData(data.value, options);
			this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData}); 
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		setBatchDocument(){
			let date = format(new Date(), 'yyyy-MM-dd');
			let isBookOpen = this.checkBookOpen(date);
			if (isBookOpen === true) {
				let data = {
					document_type: 'CR',
					document_date: date,
					edit_by: this.currUser.id,
				};
				if(this.dist_data){
					if('cash_receipt' in this.dist_data){
						const distData = this.dist_data;
						if(!('batch_number' in distData.cash_receipt)){
							this.$store.dispatch('trxdisb/getDefaultBatchNumber', data);
						} else {
							this.$router.push('/trxcr/create');
						}
					}else{
						this.$store.dispatch('trxdisb/getDefaultBatchNumber', data);
					}
				} else {
					this.$store.dispatch('trxdisb/getDefaultBatchNumber', data);
				}
			} else {
				this.createDisbDialog = true;
			}
		},
		checkBookOpen(date){
			let currentMonth = getMonth(parseISO(date));
			let currentYear = getYear(parseISO(date));

			let isBookOpen = true;
			
			this.data.forEach(account_period => {
				if (account_period.period_no === (currentMonth + 1) && account_period.fiscal_year === currentYear) {
					if (account_period.cash_receipt === 'CLOSED') {
						isBookOpen = false;
					} else {
						isBookOpen = true;
					}
				} 
			});

			return isBookOpen;
		},
		retrieveTransaction(){

		},
		removeTransaction(){
			let data = this.dist_data;
			
			const arrDet = ['details', 'headers', 'date', 'bank_account_id', 'isConfidential', 'document_type','currency_id', 'dollar_rate', 'no_receipt', 'acknowledgement_receipt', 'official_receipt', 'nr', 'or', 'ar'];
			if(data){
				if('cash_receipt' in data){
					for (let l = 0; l < arrDet.length; l++) {
						if(arrDet[l] in data.cash_receipt){
							delete data.cash_receipt[arrDet[l]];
						}
					}
				}
			}
			this.$store.commit('trxdisb/SET_DIST_DATA', '');
			this.$store.commit('trxdisb/SET_DIST_DATA', data);
			this.unsavedTransaction = false;
		},
		async getAcctperiodmain(){
			this.data = this.acctperiodmains.data;
			this.nextPage= this.acctperiodmains.next_page_url;
			let page = 2;

			while (this.nextPage !== null){
				await this.$store.dispatch('acctperiodmain/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page++;
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		getData(){
			this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: this.month_year + '-01'});
		},
		viewDetail(item){
			item.table = 'cash_receipt';
			let routeData = this.$router.resolve({path: '/preview-ereceipt/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 
		},
		// async getAllData(){
		// 	this.trxdisbs = this.trxdisbs.data;
		// 	this.nextPageTrx = this.trxdisbs.next_page_url;
		// 	let page = 2;

		// 	while (this.nextPageTrx !== null && page !== this.banks.current_page){
		// 		await this.$store.dispatch('trxdisb/getData', page).then(response => {
		// 			this.nextPageTrx = response.data.data.next_page_url;
		// 			response.data.data.data.forEach(details => {
		// 				this.trxdisbs.push(details);
		// 			});
		// 		});
		// 		page ++;
		// 	}
		// },
	}
};

</script>

<style scoped>
	.cash-receipt-title h5 {
		color: #FFF;
		font-size: 25px;
	}
	
	.date-filter {
		display: flex;
		flex-direction: row;
		position: relative;
		top: 10.5px; 
		right: 10px;
	}
	.date-filter-picker {
		width: 150px;
	}
	.date-filter-picker:first-child {
		margin-right: 10px;
	}

	.document-card-title {
		padding: 0;
	}
</style>
